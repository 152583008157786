import { render, staticRenderFns } from "./SetXpActionSetupView.vue?vue&type=template&id=2dd5a8ac&scoped=true&"
import script from "./SetXpActionSetupView.vue?vue&type=script&lang=ts&"
export * from "./SetXpActionSetupView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dd5a8ac",
  null
  
)

export default component.exports










import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import ActionTargets from "@/components/TriggerSetup/components/ActionTargets/ActionTargets.vue";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ActionTargets,
  }
})
export default class SetXpActionSetupView extends Mixins<ActionSetupView>(ActionSetupView) {

}
